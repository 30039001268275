import React from "react"
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({ className, assetUrl, alt }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1600) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Img 
    fluid={
      allImageSharp.nodes.find(n => n.fluid.originalName === assetUrl).fluid
    } 
    alt={alt}
    className={className} 
    />
  );
}

Image.propTypes = {
  className: PropTypes.string,
  assetUrl: PropTypes.string,
  alt: PropTypes.string,
};

export default Image;